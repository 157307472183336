<template>
  <main>
    <section id="account_member">
      <div class="w1300 layout_container">

        <!-- 內頁主標題 -->
        <div class="main_title_box mg-b-50">
          <h3 class="main_title txt-bold">會員資料變更</h3>
          <div class="img_box">
            <img src="@/statics/img/index/icon_title2.svg" alt="">
          </div>
        </div>

        <!-- form area -->
        <div class="form_box">
          <form id="form_account_member" action="" method="post">

            <!-- 申請辦法 -->
            <div class="form_description">
              <div class="main_description_box mg-tb-10">
                <h4 class="main_description_title txt-light_green txt-bold">申請辦法</h4>
              </div>
              <!-- <span class="small txt-red">{{ passwordErrorMsg }}</span> -->
            </div>
            <div class="form_interactive">
              <Password
                class="w100"
                v-model="userUpdate.current_password"
                :item="inputFormat.originalPasswordInput"
              />
              <Password
                class="w50"
                v-model="userUpdate.password"
                :item="inputFormat.newPasswordInput"
                :rules="[
                  val => val.length > 5 || '長度錯誤!',
                  [/^([a-zA-Z]+\d+|\d+[a-zA-Z]+)[a-zA-Z0-9]*$/, '密碼格式錯誤']
                ]"
              />
              <Password
                class="w50"
                v-model="confirmPassword"
                :item="inputFormat.confirmPasswordInput"
                :rules="[
                  val => val.length > 5 || '長度錯誤!',
                  [/^([a-zA-Z]+\d+|\d+[a-zA-Z]+)[a-zA-Z0-9]*$/, '密碼格式錯誤']
                ]"
              />
              <span class="small w100 txt-red">{{ confirmPasswordErrorMsg }}</span>
            </div>

            <!-- 基本資料 -->
            <div class="form_description">
              <div class="main_description_box mg-tb-10">
                <h4 class="main_description_title txt-light_green txt-bold">基本資料</h4>
              </div>
            </div>
            <div class="form_interactive">
              <div class="half">
                <div class="fixed_info w33">
                  <p class="fixed_info_title">姓名</p>
                  <p class="fixed_info_content">{{ fixedInfo.realname }}</p>
                </div>
                <div class="fixed_info w33">
                  <p class="fixed_info_title">會員編號</p>
                  <p class="fixed_info_content">{{ fixedInfo.no }}</p>
                </div>
                <div class="fixed_info w33">
                  <p class="fixed_info_title">性別</p>
                  <p class="fixed_info_content">{{ fixedInfo.gender }}</p>
                </div>
              </div>
              <div class="half">
                <div class="fixed_info w50">
                  <p class="fixed_info_title">出生年月日</p>
                  <p class="fixed_info_content">{{ fixedInfo.birthday }}</p>
                </div>
                <div class="fixed_info w50">
                  <p class="fixed_info_title">身分證字號</p>
                  <p class="fixed_info_content">{{ fixedInfo.id_number }}</p>
                </div>
              </div>
              <TextInput
                class="w50"
                v-model.trim="userUpdate.phone"
                :item="inputFormat.mobileInput"
                :rules="[
                  val => !!val || '必填欄位！',
                  [/^09\d{8}$/, '手機格式錯誤']
                ]"
                :maxlength="10"
              />
              <div class="half">
                <TextInput
                  class="w50"
                  v-model.trim="userUpdate.jobs.office_tel"
                  :item="inputFormat.telePublicInput"
                  :maxlength="10"
                />

                <TextInput
                  class="w50"
                  v-model.trim="userUpdate.tel"
                  :item="inputFormat.teleHomeInput"
                  :maxlength="10"
                />
              </div>
              <TextInput
                class="w50"
                v-model.trim="userUpdate.email"
                :item="inputFormat.emailInput"
                :rules="[
                  val => !!val || '必填欄位！',
                  [/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/,
                  '信箱格式錯誤'],
                ]"
              />
              <TextInput
                class="w50"
                v-model.trim="userUpdate.memo.line_id"
                :item="inputFormat.lineInput"
              />
              <div class="select_container w100">
                <p><span class="txt-red txt-regular">*</span>戶籍地址</p>
                <div class="select_container-left err_message_box w50">
                  <span class="err_message txt-red small">{{ homeAddressErrorMsg }}</span>
                  <select
                    v-model="userUpdate.city"
                    class="select_city address_top w33"
                    @change="resetHomeSelect"
                  >
                    <option value="" disabled selected>請選擇</option>
                    <option
                      v-for="(town, index) in homeTowns"
                      :key="index"
                      :value="town"
                    >{{ town }}
                    </option>
                  </select>
                  <select
                    v-model="userUpdate.area"
                    class="select_city address_top w33"
                    @change="updateHomeZip"
                  >
                    <option value="" disabled selected>請選擇</option>
                    <option
                      v-for="(area, index) in homeAreas"
                      :key="index"
                      :value="area"
                    >{{ area }}
                    </option>
                  </select>
                  <input
                    :value="userUpdate.zipcode"
                    type="text"
                    readonly
                    class="address_top w33"
                    placeholder="郵遞區號"
                  >
                </div>
                <div class="select_container-right w50">
                  <TextInput
                    class="address_top"
                    v-model.trim="userUpdate.addr"
                    :item="inputFormat.addressHomeDetailInput"
                    :rules="[
                      val => !!val || '必填欄位！',
                    ]"
                  />
                </div>
              </div>
            </div>

            <!-- 服務資料 -->
            <div class="form_description">
              <div class="main_description_box mg-tb-10">
                <h4 class="main_description_title txt-light_green txt-bold">服務資料</h4>
              </div>
            </div>
            <div class="form_interactive">
              <TextInput
                class="w50"
                v-model.trim="userUpdate.jobs.department"
                :item="inputFormat.departmentInput"
                :rules="[
                  val => !!val || '必填欄位！',
                  val => val.length > 1 || '長度錯誤!',
                ]"
              />
              <TextInput
                class="w50"
                v-model.trim="userUpdate.jobs.hospital_name"
                :item="inputFormat.hospitalInput"
                :rules="[
                  val => !!val || '必填欄位！',
                  val => val.length > 1 || '長度錯誤!',
                ]"
              />
              <div class="select_container w100">
                <p><span class="txt-red txt-regular star_hidden">*</span>院所地址</p>
                <div class="select_container-left err_message_box w50">
                  <span class="err_message txt-red small">{{ hospitalAddressErrorMsg }}</span>
                  <select
                    v-model="userUpdate.jobs.hospitalCity"
                    class="select_city address_top w33"
                    @change="resetHospitalSelect"
                  >
                    <option value="" selected>請選擇</option>
                    <option
                      v-for="(town, index) in hospitalTowns"
                      :key="index"
                      :value="town"
                    >{{ town }}
                    </option>
                  </select>
                  <select
                    v-model="userUpdate.jobs.hospitalArea"
                    class="select_city address_top w33"
                    @change="updateHospitalZip"
                  >
                    <option value="" selected>請選擇</option>
                    <option
                      v-for="(area, index) in hospitalAreas"
                      :key="index"
                      :value="area"
                    >{{ area }}
                    </option>
                  </select>
                  <input
                    :value="userUpdate.jobs.hospitalZipcode"
                    type="text"
                    readonly
                    class="address_top w33"
                    placeholder="郵遞區號"
                  >
                </div>
                <div class="select_container-right w50">
                  <TextInput
                    class="address_top"
                    v-model.trim="userUpdate.jobs.hospitalAddr"
                    :item="inputFormat.addressHospitalDetailInput"
                  />
                </div>
              </div>
            </div>

            <div class="form_submit txt-center">
              <button
                type="button"
                class="btn p"
                :class="{ btnDisable: isBtnDisable }"
                @click="onSubmit"
              >送出</button>
            </div>

          </form>
        </div>

      </div>
    </section>

  </main>
</template>

<script>
import cities from '@/data/cities.json';
import Password from '@/components/form/Password.vue';
import TextInput from '@/components/form/TextInput.vue';

import { userInfo, userUpdate } from '@/lib/http';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'AccountIndex',
  data() {
    return {
      /**
       * Static Data
       */
      confirmPassword: '',
      checkUserUpdate: '',
      isBtnDisable: false,
      homeAddressErrorMsg: '',
      hospitalAddressErrorMsg: '',
      passwordErrorMsg: '',
      confirmPasswordErrorMsg: '',
      inputFormat: {
        originalPasswordInput: {
          label: '原始密碼',
          reminder: '',
          placeholder: '請輸入您的原始密碼',
        },
        newPasswordInput: {
          label: '新密碼',
          reminder: '密碼長度請設定6位以上英數混合',
          placeholder: '請輸入新密碼',
        },
        confirmPasswordInput: {
          label: '確認密碼',
          reminder: '',
          placeholder: '請再次輸入新密碼',
        },
        mobileInput: {
          label: '手機號碼',
          type: 'text',
          placeholder: '請輸入手機號碼',
          required: true,
        },
        telePublicInput: {
          label: '電話(公)',
          type: 'text',
          placeholder: '0212345678',
          required: false,
        },
        teleHomeInput: {
          label: '電話(家)',
          type: 'text',
          placeholder: '0212345678',
          required: false,
        },
        emailInput: {
          label: 'Email',
          type: 'email',
          placeholder: '請輸入完整Email',
          required: true,
        },
        lineInput: {
          label: 'LINE ID',
          type: 'text',
          placeholder: '請輸入LINE ID',
          required: false,
        },
        departmentInput: {
          label: '科別',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        hospitalInput: {
          label: '院所名稱(例如：台灣居家醫療醫學會)',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        addressHomeDetailInput: {
          label: '',
          type: 'text',
          placeholder: '請填寫戶籍地址',
          required: true,
        },
        addressHospitalDetailInput: {
          label: '',
          type: 'text',
          placeholder: '請填寫院所地址',
          required: false,
        },
      },
      /**
       * Axios Response Data
       */
      fixedInfo: {
        realname: '',
        no: '',
        gender: '',
        birthday: '',
        id_number: '',
      },
      userUpdate: {
        current_password: '',
        password: '',
        phone: '',
        tel: '',
        email: '',
        memo: {
          line_id: '',
        },
        city: '',
        area: '',
        zipcode: '',
        addr: '',
        jobs: {
          department: '',
          office_tel: '',
          hospital_name: '',
          hospitalCity: '',
          hospitalArea: '',
          hospitalZipcode: '',
          hospitalAddr: '',
        },
      },
    };
  },
  created() {
    /**
     * 會員基本資料
     */
    if (this.hasToken) {
      userInfo().then((result) => {
        // console.log(result);
        this.fixedInfo.realname = result.realname;
        this.fixedInfo.no = result.no;
        if (result.gender === 'F') {
          this.fixedInfo.gender = '女';
        } else {
          this.fixedInfo.gender = '男';
        }
        this.fixedInfo.birthday = result.birthday;
        this.fixedInfo.id_number = result.id_number;
        if (result.phone) {
          this.userUpdate.phone = result.phone;
        }
        if (result.tel) {
          this.userUpdate.tel = result.tel;
        }
        if (result.jobs.office_tel) {
          this.userUpdate.jobs.office_tel = result.jobs.office_tel;
        }
        if (result.email) {
          this.userUpdate.email = result.email;
        }
        if (result.memo.line_id) {
          this.userUpdate.memo.line_id = result.memo.line_id;
        }
        if (result.city) {
          this.userUpdate.city = result.city;
        }
        if (result.area) {
          this.userUpdate.area = result.area;
        }
        if (result.zipcode) {
          this.userUpdate.zipcode = result.zipcode;
        }
        if (result.addr) {
          this.userUpdate.addr = result.addr;
        }
        if (result.jobs.department) {
          this.userUpdate.jobs.department = result.jobs.department;
        }
        if (result.jobs.hospital_name) {
          this.userUpdate.jobs.hospital_name = result.jobs.hospital_name;
        }
        if (result.jobs.hospitalCity) {
          this.userUpdate.jobs.hospitalCity = result.jobs.hospitalCity;
        }
        if (result.jobs.hospitalArea) {
          this.userUpdate.jobs.hospitalArea = result.jobs.hospitalArea;
        }
        if (result.jobs.hospitalZipcode) {
          this.userUpdate.jobs.hospitalZipcode = result.jobs.hospitalZipcode;
        }
        if (result.jobs.hospitalAddr) {
          this.userUpdate.jobs.hospitalAddr = result.jobs.hospitalAddr;
        }

        /** 複製一份送出資料，若完全沒有更改任何資料卻想送出時，根據此拷貝來將其擋下 */
        this.checkUserUpdate = JSON.parse(JSON.stringify(this.userUpdate));
      });
    }
  },
  computed: {
    ...mapGetters([
      'hasToken',
    ]),
    homeTowns() {
      return cities.map((city) => city.name);
    },
    homeAreas() {
      const homeTownIndex = this.homeTowns.indexOf(this.userUpdate.city);
      if (homeTownIndex >= 0) {
        return cities[homeTownIndex].areas.map((area) => area.name);
      }
      return [];
    },
    hospitalTowns() {
      return cities.map((city) => city.name);
    },
    hospitalAreas() {
      const hospitalTownIndex = this.hospitalTowns.indexOf(this.userUpdate.jobs.hospitalCity);
      if (hospitalTownIndex >= 0) {
        return cities[hospitalTownIndex].areas.map((area) => area.name);
      }
      return [];
    },
  },
  methods: {
    ...mapMutations([
      'toggleModalInfo',
      'updateModalInfoContent',
      'assignUrlRedirect',
    ]),
    updateHomeZip() {
      const homeTownIndex = this.homeTowns.indexOf(this.userUpdate.city);
      const homeAreaIndex = this.homeAreas.indexOf(this.userUpdate.area);
      if (homeAreaIndex >= 0) {
        this.userUpdate.zipcode = cities[homeTownIndex].areas[homeAreaIndex].zip;
      }
      if (homeAreaIndex === -1) {
        this.userUpdate.zipcode = '';
      }
      this.addressHomeTest();
    },
    updateHospitalZip() {
      const hospitalTownIndex = this.hospitalTowns.indexOf(this.userUpdate.jobs.hospitalCity);
      const hospitalAreaIndex = this.hospitalAreas.indexOf(this.userUpdate.jobs.hospitalArea);
      if (hospitalAreaIndex >= 0) {
        this.userUpdate.jobs.hospitalZipcode = cities[hospitalTownIndex]
          .areas[hospitalAreaIndex].zip;
      }
      if (hospitalAreaIndex === -1) {
        this.userUpdate.jobs.hospitalZipcode = '';
      }
      // this.addressHospitalTest();
    },
    resetHomeSelect() {
      this.userUpdate.area = '';
      this.userUpdate.zipcode = '';
      this.addressHomeTest();
    },
    resetHospitalSelect() {
      this.userUpdate.jobs.hospitalArea = '';
      this.userUpdate.jobs.hospitalZipcode = '';
      // this.addressHospitalTest();
    },
    addressHomeTest() {
      if (!this.userUpdate.city || !this.userUpdate.area) {
        this.homeAddressErrorMsg = '必選欄位！請選擇縣市及區域！';
      }
      if (this.userUpdate.city && this.userUpdate.area) {
        this.homeAddressErrorMsg = '';
      }
    },
    // addressHospitalTest() {
    //   if (!this.user.addressHospital.town || !this.user.addressHospital.area) {
    //     this.hospitalAddressErrorMsg = '必選欄位！請選擇縣市及區域！';
    //   }
    //   if (this.user.addressHospital.town && this.user.addressHospital.area) {
    //     this.hospitalAddressErrorMsg = '';
    //   }
    // },
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      this.addressHomeTest();
      if (this.homeAddressErrorMsg !== '') {
        errorList.push(true);
      }
      if (this.userUpdate.current_password && this.userUpdate.password && this.confirmPassword) {
        if (this.userUpdate.password === this.confirmPassword) {
          this.confirmPasswordErrorMsg = '';
        } else {
          errorList.push(true);
          this.confirmPasswordErrorMsg = '新密碼及確認密碼應相符';
        }
      } else if (!this.userUpdate.current_password
              && !this.userUpdate.password
              && !this.confirmPassword) {
        this.passwordErrorMsg = '';
      } else {
        errorList.push(true);
        this.passwordErrorMsg = '新密碼及確認密碼應相符';
      }
      if (errorList.indexOf(true) === -1) {
        if (JSON.stringify(this.userUpdate) !== JSON.stringify(this.checkUserUpdate)) {
          this.isBtnDisable = true;
          this.passwordErrorMsg = '';
          userUpdate(this.userUpdate).then((result) => {
            if (result.data.message === 'success') {
              this.updateModalInfoContent('會員資料已更新');
              this.toggleModalInfo(true);

              /** 送出後，拷貝一份新資料作為 */
              this.checkUserUpdate = JSON.parse(JSON.stringify(this.userUpdate));

              /** 返回頁面頂端 */
              window.scrollTo({
                top: 0,
                // behavior: 'smooth',
              });
            } else {
              this.updateModalInfoContent(result.data.message);
              this.toggleModalInfo(true);
            }
            this.isBtnDisable = false;
          });
        } else {
          this.updateModalInfoContent('無更新資料，返回表單');
          this.toggleModalInfo(true);
        }
      } else {
        this.updateModalInfoContent('必填欄位尚未完成！');
        this.toggleModalInfo(true);
      }
    },
  },
  components: {
    Password,
    TextInput,
  },
};
</script>
